import "./IntroSection.css";

export interface myinfo {
    name: string;
    logo: string;
    profile_img: string;
    about: string;
    social_link: string;
    projects: projects;
    skillsandtools: skillsandtools;
}

export interface projects {
    name: string;
    about?: string;
    logo?: string;
    demo_img?: string;
    github?: string;
    website_link?: string;
    startedate?: string;
    endedate?: string;
}

export interface skillsandtools {
    name: string;
    logo_link: string;
}

const IntroSection = () => {

    const locatemouse = (e:any) => {
        // console.log(e);
        const area: any = document.querySelector(".introimg");
        const screenWidth = window.innerWidth;
        const accessArea = screenWidth - area.clientWidth;

        let positionX;
        positionX = (e.pageX-accessArea)/20
        if (positionX > -50 && positionX < 50) {
            // console.log(`pageX - ${positionX}`);
            const left_eye: any = document.getElementById("left_eye");
            const right_eye: any = document.getElementById("right_eye-2");
            left_eye.style.transform = `translateX(${positionX}px)`
            right_eye.style.transform = `translateX(${positionX}px)`
        }
    }

    return (
        <section className="IntroSection">
            <div className="intro-text-wraper">
                <h1 className="intro-text">
                    Hi,<br />
                    my name is <br />
                    lalit sekhar behera. <br />
                </h1>
            </div>
            <div onMouseMove={locatemouse} className="intro-img-wraper">
                <svg className="introimg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
                    <defs>
                        <style>
                            {/* .cls-1{fill:none;}.cls-2{fill:#fff;}.cls-3{fill:#ff8d37;} */}
                        </style>
                    </defs>
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="background">
                            <rect className="cls-1" width="1080" height="1080"/>
                        </g>
                        <g id="main_body">
                            <g id="right_eye" data-name="right eye">
                                <g id="body">
                                    <path className="cls-2" d="M921.87,464.06S851.61,349.17,775.42,343s-6.76,7-6.76,7L642.1,618.35l151.33,30.51S817,575.72,811.4,540c0,0,37,32.3,50.89,79.32,13.2,44.56,11.92,138.89,44.77,148.93S998.46,574.36,921.87,464.06Z"/>
                                    <path className="cls-2" d="M390.28,333.85s118.21-22.72,306.63-1.4L644.83,542.19l-61.75,92.68-113.4-16.08Z"/>
                                    <path className="cls-2" d="M317.22,696.82,436,674.19l344,11.32s-31.29,66.21-69.08,102.64S652,863,652,863s-61.1,82.21-94.17,169.85a8.38,8.38,0,0,1-15.55.36c-14.21-33.18-47-102-100.19-171.37,0,0-28.73-39-52.22-65.16C389.85,796.66,345.35,750.18,317.22,696.82Z"/>
                                    <path className="cls-2" d="M168.91,468.15s70.26-114.89,146.45-121,.43,4.24.43,4.24l128,268.5L297.35,653s-23.58-73.14-18-108.89c0,0-37,32.3-50.89,79.32-13.2,44.56-11.92,138.9-44.77,148.94S92.32,578.45,168.91,468.15Z"/>
                                    <path className="cls-3" d="M496.88,446.53h96.21A9,9,0,0,1,599.42,462l-48.1,47.24a9,9,0,0,1-12.66,0L490.55,462A9,9,0,0,1,496.88,446.53Z"/>
                                    <path className="cls-3" d="M461.28,684.28,410.44,815.13a8.68,8.68,0,0,0,1.26,8.47l29,36.94a6.38,6.38,0,0,0,11-1.88l60.3-176.32Z"/>
                                    <path className="cls-3" d="M633.88,684.28l50.84,130.85a8.68,8.68,0,0,1-1.26,8.47l-29,36.94a6.38,6.38,0,0,1-11-1.88l-60.3-176.32Z"/>
                                    <path className="cls-3" d="M297.86,654.76a14.71,14.71,0,0,1,11.56-19.41c40.32-6.46,145.36-21.53,234.81-19.12,0,0,152.85-1,248.85,16.74a5.79,5.79,0,0,1,4.56,7.14,225.91,225.91,0,0,1-23.57,56,5.81,5.81,0,0,1-6,2.79c-29.63-5.07-224.46-35.49-445.33.08a5.79,5.79,0,0,1-5.86-2.66C313.32,690.58,305.72,677,297.86,654.76Z"/>
                                    <circle className="cls-2" cx="357.98" cy="663.34" r="14.17"/>
                                    <circle className="cls-2" cx="405.85" cy="657.21" r="14.17"/>
                                    <circle className="cls-2" cx="459.72" cy="652.62" r="14.17"/>
                                    <circle className="cls-2" cx="511.3" cy="650.32" r="14.17"/>
                                    <circle className="cls-2" cx="581.51" cy="649.04" r="14.17"/>
                                    <circle className="cls-2" cx="632.83" cy="650.83" r="14.17"/>
                                    <circle className="cls-2" cx="687.47" cy="654.66" r="14.17"/>
                                    <circle className="cls-2" cx="734.7" cy="660.02" r="14.17"/>
                                    <path className="cls-3" d="M771.55,350.61l-128,268.49-65.9-2.78L693.45,334.56a4.68,4.68,0,0,1,4.73-2.86c8.48.76,33.34,4.15,70.35,12.67A4.44,4.44,0,0,1,771.55,350.61Z"/>
                                    <circle className="cls-2" cx="771.98" cy="501.6" r="24.77"/>
                                    <path className="cls-3" d="M315.79,351.37l128,268.5,65.9-2.78L393.89,335.33a4.66,4.66,0,0,0-4.73-2.86c-8.48.76-33.34,4.14-70.35,12.66A4.45,4.45,0,0,0,315.79,351.37Z"/>
                                </g>
                                <g id="Head">
                                    <ellipse id="face" className="cls-2" cx="546.15" cy="160.66" rx="222.51" ry="145.28"/>
                                    <rect id="eye_background" x="367.71" y="122.7" width="350.3" height="94.98" rx="47.49" transform="translate(-1.35 4.35) rotate(-0.46)"/>
                                    <circle id="left_eye" className="cls-2" cx="454.62" cy="170.19" r="24.77"/>
                                    <circle id="right_eye-2" data-name="right_eye" className="cls-2" cx="631.21" cy="168.06" r="24.77"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </section>
    );
};

export default IntroSection;