import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { projects } from '../Components/IntroSection';
import ProjectCard from "../Components/ProjectCard";
import { actionCreators } from '../store';
import "./Projects.css";
import "./Contact.css";
import { useState } from 'react';

const Projects = () => {

    const dispatch = useDispatch()
    const mydata:projects[] = useSelector((state:any) => state.mydata.projects)
    const state:any = useSelector((state:any) => state.addProjectSearchFilter)
    useEffect(()=>{
        const { getMydata } = bindActionCreators(actionCreators, dispatch)
        !mydata && getMydata()
    },[dispatch, mydata])

    const [searchKeyWord, setsearchKeyWord] = useState<string>('')

    useEffect(()=>{
        if (typeof state === "string") {
            setsearchKeyWord(state)
        }
    },[state])
    
    return (
        <div className="Projects">
            <span className="animation-bg projects-bg"></span>
            {
                mydata?.map(project => {
                    if (project.name.indexOf(searchKeyWord) !== -1) {
                        return <ProjectCard key={project.name} name={project.name} logo={project.logo} github={project.github} website_link={project.website_link}/>
                    }
                    return null
                })
            }
        </div>
    );
};

export default Projects;