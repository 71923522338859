import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from './Screens/Home';
import Contact from './Screens/Contact';
import Portfolio from './Screens/Portfolio';
import Projects from './Screens/Projects';
import Header from './core/Header';
import Footer from './core/Footer';
import FourOFour from './core/FourOFour';
import PrivacyPolicy from './Screens/PrivacyPolicy';


const Routers = () => {
    return (
        <BrowserRouter>
            <Header/>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/portfolio" component={Portfolio}/>
                <Route exact path="/projects" component={Projects}/>
                <Route exact path="/contact" component={Contact}/>
                <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                <Route component={FourOFour}/>
            </Switch>
            <Footer/>
        </BrowserRouter>
    );
};

export default Routers;