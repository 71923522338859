export interface actionObj {
    type: String, 
    payload: string
}

export interface stateis {
    num: number
}

const reducer = (state = [], action: actionObj) =>{
    switch (action.type) {
        case "GET-MY-DATA":
            return action.payload
    
        default:
            return state
    }
}

export default reducer;