import { useEffect, useState } from 'react';
import './SkillsAndTools.css';
const SkillsAndTools = () => {

    interface Toolandskill {
        name: string;
        logo_link:string;
    }

    const [data,setdata] = useState<Toolandskill[]>();
    
    useEffect(()=>{
        fetch('https://raw.githubusercontent.com/LALIT-SEKHAR/mywebsite-bucket/master/mywebsite.json')
        .then( res => res.json())
        .then( data => setdata(data.skillsandtools))
        .catch( err => console.log(err))
    },[])

    return (
        <div className="SkillsAndTools">
            <h1 className="SkillsAndTools-name">skills and tools</h1>
            <span className="SkillsAndTools-img-holder">
                <span>

                {
                    data?.map(item => {
                        return <img className="SkillsAndTools-img" key={item.name} src={item.logo_link} alt={item.name} />
                    })
                }
                </span>
            </span>
        </div>
    );
};

export default SkillsAndTools;