import './Home.css';
import IntroSection from '../Components/IntroSection';
import SkillsAndTools from '../Components/SkillsAndTools';
import WebProjects from '../Components/WebProjects';

function App() {
  return (
    <div className="App">
      <IntroSection/>
      <SkillsAndTools/>
      <WebProjects/>
    </div>
  );
}

export default App;
