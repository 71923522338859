import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { myinfo } from '../Components/IntroSection';
import { actionCreators } from '../store';
import "./Header.css";

const Header = () => {

    const location = useLocation();

    const [mydataa,setmydataa] = useState<myinfo>();
    const [searchinput,setsearchinput] = useState<string>('');
    
    const dispatch = useDispatch()
    useEffect(()=>{
        setHeadrres()
        fetch('https://raw.githubusercontent.com/LALIT-SEKHAR/mywebsite-bucket/master/mywebsite.json')
        .then( res => res.json())
        .then( data => {
            setmydataa(data);
        })
        .catch( err => console.log(err))
    },[])
    
    const { addProjectSearchFilter } = bindActionCreators(actionCreators, dispatch)
    const chengeColor = (path:string) => {
        if (location.pathname === path) {
            return {color: "#ffffff"}
        }
    }
    
    const getSearchProject = (e:any) => {
        e.preventDefault();
        console.log(e);
        setsearchinput('')
    }
    
    const searchProject = (e:any) => {
        e.preventDefault()
        setsearchinput(e.target.value)
        addProjectSearchFilter(e.target.value)
    }

    document.addEventListener('scroll', (e:any) => {
            let present_windowHeight = window.pageYOffset;
            let header_height = document.querySelector(".header")?.clientHeight ?? 80
            let revealpoint = 0;
            
            if (present_windowHeight > header_height+revealpoint) {
                document.querySelector(".header")?.classList.add('outHeader');
            } 
            else {
                document.querySelector(".header")?.classList.remove('outHeader');
            }
    })

    window.addEventListener('resize', (e:any) => {
        setHeadrres()
    })

    const setHeadrres = () => {
        const newWidth = window.innerWidth;
        const  header = document.querySelector(".header")
        const  menu_btn = document.querySelector(".menu-btn")
        const  links_wraper = document.querySelector(".links-wraper")
        const navication = document.querySelector("nav")
        if (newWidth <= 500) {
            header?.classList.add('header-mob');
            menu_btn?.classList.add('menu-btn-mob');
            navication?.classList.add('navication-invis');
            links_wraper?.classList.add('links-wraper-mob-vis');
        }else{
            header?.classList.remove('header-mob');
            menu_btn?.classList.remove('menu-btn-mob');
            navication?.classList.remove('navication-invis');
            links_wraper?.classList.remove('links-wraper-mob-vis');
            
        }
    }
    
    const toggleMenu = () => {
        const navication = document.querySelector("nav");
        const menu_icon = document.querySelector(".menu-icon");
        if (navication?.className === 'navication-vis') {
            navication?.classList.add('navication-invis');
            menu_icon?.classList.remove('menu-icon-close');
        }else{
            navication?.classList.remove('navication-invis');
            menu_icon?.classList.add('menu-icon-close');
        }
        
    }

    return (
        <header className="header">
            <div className="logo-AND-menubtn logo-AND-menubtn-mob">
                <Link to="/" className="image-wraper">
                    <img className="Header-logo" src={mydataa?.logo} alt={mydataa?.name} />
                </Link>
                <div onClick={toggleMenu} className="menu-btn">
                    <span className="menu-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </div>
            </div>
            <nav className="navication-vis">
                <ul className="links-wraper">
                    {location.pathname === "/projects" && <div className="project_Search_Bar" onSubmit={getSearchProject}><input onChange={searchProject} value={searchinput} type="text" placeholder="search projects"/></div>}
                    <Link to="/portfolio"><li style={chengeColor("/portfolio")} className="links">portfolio</li></Link>
                    <Link to="/projects"><li style={chengeColor("/projects")} className="links">projects</li></Link>
                    <Link to="/contact"><li style={chengeColor("/contact")} className="links">contact</li></Link>
                </ul>
            </nav>
        </header>
    );
};

export default Header;