import { actionObj } from "./mydataReducers";

const reducer = (state = [], action: actionObj) =>{
    switch (action.type) {
        case "GET-MY-DATA":
            return action.payload
    
        case "ADD-PROJECT-SEARCH-FILTER":
            return action.payload
    
        default:
            return state
    }
}

export default reducer;