import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { projects } from '../Components/IntroSection';
import { actionCreators } from '../store';
import "./Contact.css";
import "./Portfolio.css";

const Portfolio = () => {

    const dispatch = useDispatch()
    const mydata:projects[] = useSelector((state:any) => state.mydata.projects?.sort(function(a:any, b:any){return a.startedate > b.startedate}))
    useEffect(()=>{
        const { getMydata } = bindActionCreators(actionCreators, dispatch)
        !mydata && getMydata()
    },[dispatch, mydata])
    

    return ( 
        <div className="Portfolio">
            <span className="animation-bg portfolio-bg"></span>
            <div className="Portfolio-content">
                {
                mydata?.map((pro) => {
                    return <div key={pro.name} className="Portfolio-card-wraper">
                        <div className="Portfolio-card">
                            <span className="Portfolio-card-startdate">{pro.startedate}</span>
                            <span><img className="Portfolio-card-logo" src={pro.logo} alt={pro.name} /></span>
                            <span><a href={pro.github}><img className="Portfolio-card-github"  src="github_icon_white.svg" alt="github" /></a></span>
                        </div>
                    </div>
                    
                })
                }
            </div>
        </div>
    );
};

export default Portfolio;