import { useEffect, useState } from "react";
import "./WebProjects.css";

const WebProjects = () => {

    interface Projects {
        name: string;
        about: string;
        logo: string;
        demo_img: string;
        github: string;
        website_link: string;
        startedat: string;
        endedat: string;
    }

    const [data,setdata] = useState<Projects[]>();

    useEffect(()=>{
        fetch('https://raw.githubusercontent.com/LALIT-SEKHAR/mywebsite-bucket/master/mywebsite.json')
        .then( res => res.json())
        .then( data => {
            setdata(data.projects);
        })
        .catch( err => console.log(err))
    },[])


    document.addEventListener('scroll', (e:any) => {
        // console.log(document.querySelector(".WebProjects-website-img"));
        const reveals:any = document.querySelectorAll(".reveal");
        reveals.forEach((element:any) => {
            let windowHeight = window.innerHeight;
            let revealtop = element.getBoundingClientRect().top;
            let revealpoint = 50;
    
            if (revealtop < windowHeight - revealpoint) {
                element.classList.add('reveal-active');
            } 
            else {
                element.classList.remove('reveal-active');
            }
        });
    })

    return (
        <>
            {
                data?.map(project => {
                    return (
                        <div key={project.name} className="WebProjects">
                            <div className="WebProjects-website-wraper">
                                <a rel="noreferrer noopener" target="_blank" href={project.website_link}>
                                    <img className="WebProjects-website-img reveal" src={project.demo_img} alt={project.name}/>
                                </a>
                            </div>
                            <div className="WebProjects-info-wraper">
                                <span >
                                    <img className="WebProjects-info-app-logo reveal" src={project.logo} alt="app logo" />
                                    <h1 className="WebProjects-info-name">{project.name}</h1>
                                </span>
                                <span>
                                    <p className="WebProjects-info-about">Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam molestiae ducimus iusto ipsa rerum repellat.</p>
                                </span>
                                <span>
                                    <a rel="noreferrer noopener" target="_blank" href={project.github}>
                                        <img height="100%" width="auto" src="github_icon_white.svg" alt="github" />
                                    </a>
                                </span>
                            </div>
                        </div>
                    )
                })
            }
        </>
    );
};

export default WebProjects;