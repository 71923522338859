import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../core/Loading';
import "./Contact.css";

const Contact = () => {

    const histry = useHistory();

    interface EmailType {
        subject: string;
        email: string;
        message: string;
        name: string;
    }

    const [value, setvalue] = useState<EmailType>({
        subject: '',
        name: '',
        email: '',
        message: '',
    });

    const [isloading, setisloading] = useState(false);

    const onhendelchenge = (e:any) => {
        setvalue({...value, [e.target.name]: e.target.value})
    }
    const onmessagechenge = (e:any) => {
        setvalue({...value, message: e.target.value})
    }

    const sendmail = (e:any) => {
        e.preventDefault();
        setisloading(true)
        fetch(`https://portfolio-api.lalitsekhar.dev/.netlify/functions/server/mail`,{
            method: "POST",
            body: JSON.stringify(value),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(data => {
            setisloading(false)
            histry.push('/')
        })
        .catch(err => {
            setisloading(false)
            console.log(err)
        })
    }

    return (
        <div className="Contact">
            {isloading && <Loading/>}
            <span className="animation-bg"></span>
                <div className="Contact-form">
                    <form className="formfild" onSubmit={sendmail}>
                    <fieldset className="inputfild-wraper">
                        <legend className="fildName">subject</legend>
                        <input type="text" onChange={onhendelchenge} value={value.subject} name="subject" required/>
                    </fieldset>
                    <fieldset className="inputfild-wraper">
                        <legend className="fildName">name</legend>
                        <input type="test" onChange={onhendelchenge} value={value.name} name="name" required/>
                    </fieldset>
                    <fieldset className="inputfild-wraper">
                        <legend className="fildName">email</legend>
                        <input type="email" onChange={onhendelchenge} value={value.email} name="email" required/>
                    </fieldset>
                        <fieldset className="inputfild-wraper">
                            <legend className="fildName">message</legend>
                            <textarea name=" " onChange={onmessagechenge} value={value.message} required ></textarea>
                        </fieldset>
                        <button className="emailSubmit-btn" type="submit">Send</button>
                    </form> 
                </div>
        </div>
    );
};

export default Contact;