export const getMydata = () => {
        return (dispatch:any) => {
            return fetch('https://raw.githubusercontent.com/LALIT-SEKHAR/mywebsite-bucket/master/mywebsite.json')
            .then( res => res.json())
            .then( data => {
                dispatch({
                    type:"GET-MY-DATA",
                    payload: data
                })
            })
            .catch( err => console.log(err))
        }
}
export const addProjectSearchFilter = (e:any = '') => {
    return (dispatch:any) => {
        return dispatch({
                type:"ADD-PROJECT-SEARCH-FILTER",
                payload: e
            })
    }
}