import React from 'react';
import { projects } from './IntroSection';
import "./ProjectCard.css";

const ProjectCard = (props:projects) => {

    return (
        <div className="ProjectCard">
            <a rel="noreferrer noopener" target="_blank" href={props.website_link}>
                <img className="ProjectCard-logo" src={props.logo} alt={props.name} />
            </a>
            <h2 className="ProjectCard-name">{props.name}</h2>
            <a rel="noreferrer noopener" target="_blank" href={props.github}>
                <img className="ProjectCard-github" src="github_icon_white.svg" alt="github" />
            </a>
        </div>
    );
};

export default ProjectCard;